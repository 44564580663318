export function loadScript(scriptFile) {

    const url = `https://cdn-components.oxy.elotech.com.br/${scriptFile}.${window.location.host.includes('.oxy.') ? 'js' : 'snapshot.js'}`;

    return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${url}"]`)) {
            return resolve();
        }
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.head.appendChild(script);
    });
}
